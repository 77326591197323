<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px">
    <v-card>
      <v-card-title>
        <span style="font-size: 1.75rem; font-weight: 500; padding-top: 1rem;">
          {{ add ? 'เพิ่ม' : 'ลบ' }} Tags
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="add-tags-form"
          @submit.prevent="onSubmit()">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="selectedTags"
                :items="tags"
                :search-input.sync="tagSearch"
                placeholder="เลือก Tags"
                multiple
                outlined
                hide-details
                :loading="loading"
                class="mr-2">
                <template #prepend-inner>
                  <v-icon
                    small
                    style="margin-top: 3px;">
                    mdi-tag
                  </v-icon>
                </template>
                <template #selection>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-divider class="mb-6" />
              <span style="font-size: 1.25rem; font-weight: bold; color: black;">
                {{ formData.tags.length ? 'Tags ที่เลือก' : 'ยังไม่ได้เลือก Tags' }}
              </span>
              <div class="tags-container mb-2">
                <v-chip
                  v-for="(tagSelected, index) in formData.tags"
                  :key="`tag-selected-chip-${index}`"
                  class="ma-1"
                  color="teal"
                  text-color="white"
                  small
                  label
                  close
                  @click:close="unSelectTag(index)">
                  {{ tagSelected }}
                </v-chip>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-end align-center"
              style="gap: 0.5rem;">
              <v-btn
                color="error"
                text
                :disabled="loading"
                @click="closeForm()">
                ปิด
              </v-btn>
              <v-btn
                type="submit"
                color="success"
                text
                :disabled="loading || !formData.tags.length">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AttributesAssignerProvider from '@/resources/AttributesAssignerProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'

const AttributesAssignerService = new AttributesAssignerProvider()
const ProductAttributeService = new ProductAttributeProvider()

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: () => []
    },
    add: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      formData: {
        products: [],
        tags: []
      },
      tagSearch: '',
      tags: []
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    selectedTags: {
      get () {
        return this.formData.tags
      },
      set (val) {
        this.formData.tags = val
        // this.tagSearch = ''
      }
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.formData = {
          products: this.products,
          tags: []
        }
        this.getAttributes()
      }
    }
  },
  methods: {
    async getAttributes () {
      try {
        this.loading = true

        if (this.add) {
          const { data } = await ProductAttributeService.getProductAttribute('tags', {
            page: 1,
            limit: 9999,
            sortBy: ['id'],
            sortDesc: [true]
          })

          this.tags = data.results.map((tag) => tag.name)
        } else {
          const mapped = this.products.reduce((arr, p) => [...arr, ...p.tags], [])
          this.tags = [...new Set(mapped)]
        }
      } catch (error) {
        console.error('getAttributes', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        this.$store.dispatch('Components/setLoading', {
          active: true,
          clickAble: false,
          message: 'PROCESSING...'
        })

        const valid = await this.$refs['add-tags-form'].validate()

        if (valid && this.add) {
          await AttributesAssignerService.addTags({
            products: this.formData.products.map((v) => v.id),
            tags: this.formData.tags
          })

          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'Add tags success.',
            type: 'success'
          })
        } else if (valid) {
          await AttributesAssignerService.removeTags({
            products: this.formData.products.map((v) => v.id),
            tags: this.formData.tags
          })

          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'Remove tags success.',
            type: 'success'
          })
        }

        this.closeForm()
        this.$emit('updated')
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.$store.dispatch('Components/setLoading', { active: false })
      }
    },
    closeForm () {
      this.dialog = false
    },
    unSelectTag (index) {
      const tagIndex = this.tags.findIndex((tag) => tag === this.formData.tags[index])
      if (tagIndex !== -1) {
        this.formData.tags.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
