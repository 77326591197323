import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class AttributesAssignerProvider extends HttpRequest {
  addTags (payload) {
    this.setHeader(getAuthToken())
    return this.patch('attributes-assigner/add-tags', payload)
  }

  removeTags (payload) {
    this.setHeader(getAuthToken())
    return this.patch('attributes-assigner/remove-tags', payload)
  }

  addGroupCategories (payload) {
    this.setHeader(getAuthToken())
    return this.patch('attributes-assigner/add-group-categories', payload)
  }

  removeGroupCategories (payload) {
    this.setHeader(getAuthToken())
    return this.patch('attributes-assigner/remove-group-categories', payload)
  }

  hideFromWeb (payload) {
    this.setHeader(getAuthToken())
    return this.patch('attributes-assigner/hide-from-web', payload)
  }
}

export default AttributesAssignerProvider
