<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-row>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="auto">
            <h2>Attributes Assigner</h2>
          </v-col>
          <v-col
            cols="auto"
            class="d-flex justify-end align-end">
            <search-by-tags-and-brand
              v-model="query"
              append-icon="mdi-magnify"
              tags-width="300px"
              show-collection
              :is-mobile="isMobile"
              @on-search="fetchProducts(true)" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="auto">
            <span class="pt-2">
              เลือก {{ itemSelected.length }} รายการ
            </span>
          </v-col>
          <v-col
            cols="12"
            md="auto"
            class="d-flex justify-end align-end"
            style="gap: 0.5rem;">
            <v-btn
              color="secondary"
              outlined
              small
              :loading="loading"
              :disabled="itemSelected.length === 0"
              @click="onConfirmHideFromWeb()">
              ซ่อนจากเว็บ
            </v-btn>
            <v-btn
              color="success"
              outlined
              small
              :loading="loading"
              :disabled="itemSelected.length === 0"
              @click="addTags()">
              เพิ่ม Tags
            </v-btn>
            <v-btn
              color="error"
              outlined
              small
              :loading="loading"
              :disabled="itemSelected.length === 0"
              @click="removeTags()">
              ลบ Tags
            </v-btn>
            <v-btn
              color="success"
              outlined
              small
              :loading="loading"
              :disabled="itemSelected.length === 0"
              @click="addGroupCategories()">
              เพิ่ม Group Categories
            </v-btn>
            <v-btn
              color="error"
              outlined
              small
              :loading="loading"
              :disabled="itemSelected.length === 0"
              @click="removeGroupCategories()">
              ลบ Group Categories
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="itemSelected"
          :headers="headers"
          :items="products"
          :items-per-page="query.limit"
          :loading="loading"
          show-select
          item-key="model"
          fixed-header
          hide-default-footer
          height="calc(100vh - 340px)">
          <template #[`item.image`]="{ item }">
            <div class="py-2">
              <v-img
                :src="item.photoUrls[0] || noPicture"
                :lazy-src="item.photoUrls[0] || noPicture"
                width="60"
                height="80"
                cover />
            </div>
          </template>
          <template #[`item.tags`]="{ item }">
            <div
              class="d-flex flex-row align-center flex-wrap py-2"
              style="gap: 0.25rem;">
              <v-chip
                v-for="(tag, tIndex) in item.tags"
                :key="`${tag}-tag-${tIndex}`"
                color="secondary"
                text-color="white"
                small
                label>
                {{ tag }}
              </v-chip>
            </div>
          </template>
          <template #[`item.groupCategories`]="{ item }">
            <div
              class="d-flex flex-row align-center flex-wrap py-2"
              style="gap: 0.25rem;">
              <v-chip
                v-for="(groupCategory, tIndex) in item.groupCategories"
                :key="`${groupCategory}-tag-${tIndex}`"
                color="secondary"
                text-color="white"
                small
                label>
                {{ groupCategory }}
              </v-chip>
            </div>
          </template>
          <template #[`item.categories`]="{ item }">
            <div
              class="d-flex flex-row align-center flex-wrap py-2"
              style="gap: 0.25rem;">
              <v-chip
                v-for="(category, tIndex) in item.categories"
                :key="`${category}-category-${tIndex}`"
                color="secondary"
                text-color="white"
                small
                label>
                {{ category }}
              </v-chip>
            </div>
          </template>
          <template #[`item.international`]="{ item }">
            <v-icon
              v-if="isIncludeTags(item, ['sgweb', 'interweb'])"
              color="success">
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </template>
          <template #[`item.isOnWeb`]="{ item }">
            <v-icon
              v-if="item.isOnWeb"
              color="success">
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </template>
          <template #[`item.memberPromotion`]="{ item }">
            <v-icon
              v-if="isIncludeTags(item, ['900'])"
              color="success">
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </template>
          <template #[`item.soon`]="{ item }">
            <v-icon
              v-if="isIncludeTags(item, ['soon'])"
              color="success">
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </template>
          <template #[`item.ffm`]="{ item }">
            <v-icon
              v-if="loadingFFM"
              color="secondary"
              class="spin">
              mdi-loading
            </v-icon>
            <v-icon
              v-else-if="isIncludeTags(item, fullfillmentTags)"
              color="success">
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </template>
          <template #[`item.noAction`]="{ item }">
            <v-icon
              v-if="isIncludeTags(item, ['noaction'])"
              color="success">
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="totalPage"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
    <TagsSelectorModal
      v-model="dialog"
      :products="itemSelected"
      :add="isAdd"
      @updated="fetchProducts(true)" />
    <GroupCategoriesSelectorModal
      v-model="groupCategoriesDialog"
      :products="itemSelected"
      :add="isAdd"
      @updated="fetchProducts(true)" />
  </v-container>
</template>

<script>
import AttributesAssignerProvider from '@/resources/AttributesAssignerProvider'
import ProductProvider from '@/resources/ProductProvider'
import OrderfulfillmentSetttingProvider from '@/resources/OrderfulfillmentSetttingProvider'
import SearchByTagsAndBrand from '@/components/SearchByTagsAndBrand.vue'
import ConvertQueryStringToArray from '@/assets/js/ConvertQueryStringToArray'
import NoPicture from '@/assets/image/no_picture_available.png'
import TagsSelectorModal from '../components/TagsSelectorModal.vue'
import GroupCategoriesSelectorModal from '../components/GroupCategoriesSelectorModal.vue'

const AttributesAssignerService = new AttributesAssignerProvider()
const ProductService = new ProductProvider()
const OrderfulfillmentSettingService = new OrderfulfillmentSetttingProvider()

export default {
  components: {
    SearchByTagsAndBrand,
    TagsSelectorModal,
    GroupCategoriesSelectorModal
  },
  data () {
    return {
      noPicture: NoPicture,
      headers: [
        {
          text: 'Image',
          value: 'image',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: false
        },
        // {
        //   text: 'Brand',
        //   value: 'brand',
        //   sortable: false
        // },
        // {
        //   text: 'Web Categories',
        //   value: 'categories',
        //   sortable: false
        // },
        {
          text: 'Group Categories',
          value: 'groupCategories',
          sortable: false
        },
        {
          text: 'Active on Web',
          value: 'isOnWeb',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Soon',
          value: 'soon',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Custom FFM',
          value: 'ffm',
          sortable: false,
          align: 'center'
        },
        {
          text: 'International',
          value: 'international',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Member Promotion',
          value: 'memberPromotion',
          sortable: false,
          align: 'center'
        },
        {
          text: 'No Action',
          value: 'noAction',
          sortable: false,
          align: 'center'
        }
      ],
      itemSelected: [],
      products: [],
      fullfillmentTags: [],
      query: {
        page: 1,
        limit: 100,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all',
        collection: ''
      },
      totalPage: 1,
      totalProduct: 0,
      loading: false,
      loadingFFM: false,
      dialog: false,
      groupCategoriesDialog: false,
      isAdd: false
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.fetchProducts()
      },
      deep: true
    }
  },
  mounted () {
    this.queryTags()
    this.fetchProducts()
    this.getFullFillmentTags()
  },
  methods: {
    async fetchProducts (isClear = false) {
      if (this.loading) {
        return
      }

      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.products = []
          this.itemSelected = []
        }

        this.$router.push({
          name: 'AttributesAssigner',
          query: {
            search: this.query.search,
            tags: this.query.tags || [],
            tagOperation: this.query.tagOperation,
            brand: this.query.brand,
            collection: this.query.collection
          }
        })

        this.queryTags()

        const { data } = await ProductService.getProducts(this.query)

        this.totalPage = data.pages
        this.totalProduct = data.total
        this.products = data.results
      } catch (error) {
        console.error('fetchProducts', error)
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loading = false
      }
    },
    async getFullFillmentTags () {
      if (this.loadingFFM) {
        return
      }

      try {
        this.loadingFFM = true

        const { data } = await OrderfulfillmentSettingService.getOrderFulfillmentSetting({ isDefault: 0 })

        this.fullfillmentTags = data.map((st) => st.tag)
      } catch (error) {
        console.error('getFullFillmentTags', error)
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loadingFFM = false
      }
    },
    queryTags () {
      const result = ConvertQueryStringToArray(this.$route.query)

      this.query.search = result.search ? result.search : ''
      this.query.tags = result.tags ? result.tags : []
      this.query.tagOperation = result.tagOperation ? result.tagOperation : 'OR'
      this.query.brand = result.brand ? result.brand : 'all'
      this.query.collection = result.collection ? result.collection : ''
    },
    isIncludeTags (item, tags) {
      return item.tags.some((v) => tags.includes(v))
    },
    onConfirmHideFromWeb () {
      const tobeHide = this.itemSelected.filter((v) => v.isOnWeb).length
      this.$store.dispatch('Components/setModal', {
        value: true,
        title: 'ยืนยันการซ่อนสินค้าจากเว็บ',
        message: `มีสินค้า ${tobeHide} รายการ จาก ${this.itemSelected.length} รายการ จะถูกซ่อนจากเว็บ`,
        confirmText: 'ยืนยัน',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'ปิด',
        onConfirm: () => this.hideFromWeb()
      })
    },
    async hideFromWeb () {
      try {
        this.$store.dispatch('Components/setLoading', {
          active: true,
          clickAble: false,
          message: 'PROCESSING...'
        })

        await AttributesAssignerService.hideFromWeb({
          products: this.itemSelected.map((v) => v.id)
        })

        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: 'Hide from web success.',
          type: 'success'
        })

        this.fetchProducts(true)
      } catch (error) {
        console.error('hideFromWeb', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.$store.dispatch('Components/setLoading', { active: false })
      }
    },
    addTags () {
      this.isAdd = true
      this.dialog = true
    },
    removeTags () {
      this.isAdd = false
      this.dialog = true
    },
    addGroupCategories () {
      this.isAdd = true
      this.groupCategoriesDialog = true
    },
    removeGroupCategories () {
      this.isAdd = false
      this.groupCategoriesDialog = true
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  animation: spin 1s linear infinite;
}
</style>
